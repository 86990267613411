import discountMixins from "mixins/discount.js";
import countMixins from "mixins/count";
// import dayjs from "dayjs";
import qs from "qs";
import Moment from "moment";

import { mapGetters } from "vuex";

export default {
  mixins: [discountMixins, countMixins],
  data: () => ({
    data: null,
    dataFromApi: null,
    pager: null,
    page: 1,
    per: 10,
    tabData: 0,
    month: Moment().format("YYYY-MM"),
    selected: null,
    optionsPositionRole: [],
    folderName: "",
    defaultFileName: "export",

    dataSortKey: "amount",

    dataSortIsDesc: false,
    setTopNumber: 5,

    chartColors: [
      "#EF9A9A",
      "#80CBC4",
      "#80DEEA",
      "#FFE082",
      "#B39DDB",
      "#9E9E9E",
    ],
    pieChartsConfig: [
      {
        valueKey: "amount",
      },
    ],
    pieChartsData: [],
    pieChartsDataForm: {
      labels: [],
      datasets: [
        {
          backgroundColor: [],
          data: [],
        },
      ],
    },
    lineChartsConfig: [
      {
        valueKey: "amount",
      },
      {
        valueKey: "count_number",
      },
    ],
    lineChartsDataLoaded: false,
    lineChartsData: [],
    lineChartOptions: {
      elements: {
        line: { tension: 0, capBezierPoints: false },
      },
      legend: { fontSize: 0 },
      scales: {
        xAxes: [
          {
            gridLines: { display: false },
          },
        ],
      },
      hover: { animationDuration: 0 },
      plugins: {
        datalabels: {
          formatter: (value, context) => {
            return "";
          },
          color: "#fff",
        },
      },
    },
  }),
  computed: {
    ...mapGetters({
      layoutProviderId: "base/layoutProviderId",
    }),
    tokenRole() {
      return this.$store.getters["token/tokenRole"];
    },
    tokenRolePositionRole() {
      const roleKey = this.tokenRole;
      const tokenRoleToTab = {
        store: "provider",
        provider: "store",
      };
      return tokenRoleToTab[roleKey];
    },
    profile() {
      return this.$store.getters[`member/profile`];
    },
    currTabObj() {
      return this.options[this.tabData];
    },
    currFolderName() {
      return (
        this.currTabObj.text +
        "報表 " +
        this.searchDateRange.start_date +
        "~" +
        this.searchDateRange.end_date
      );
    },
    dateOptions() {
      const todayYM = Moment().format("YYYY-MM");
      const startYM = Moment(this.profile.created_at).format("YYYY-MM");

      const diffMonth = Moment(todayYM).diff(Moment(startYM), "month") + 1;
      let num = 0;
      const options = [];

      while (num < diffMonth) {
        const curr = Moment(startYM).add(num, "M");
        const currV = curr.format("YYYY-MM");
        const option = {
          text: curr.format("YYYY年MM月"),
          value: currV,
          type: "day",
        };
        options.push(option);

        num++;
        const nextY = Moment(startYM)
          .add(num, "M")
          .format("YYYY");
        const currY = curr.format("YYYY");
        if (
          currY !== nextY ||
          (curr.format("MM") != "12" && num == diffMonth)
        ) {
          options.push({
            text: currY + "年度報表",
            value: currY,
            type: "month",
          });
        }
      }

      return options;
    },
    currDateOption() {
      const optionValue = this.month;
      return this.dateOptions.find((item) => item.value == optionValue);
    },
    searchDateRange() {
      const month = this.month;
      const curr = Moment(month);
      const dateRange = {
        start_date: "",
        end_date: "",
      };

      if (this.currDateOption.type == "month") {
        dateRange.start_date = curr.startOf("year").format("YYYY-MM-DD");
        dateRange.end_date = curr.endOf("year").format("YYYY-MM-DD");
      } else {
        dateRange.start_date = curr.startOf("month").format("YYYY-MM-DD");
        dateRange.end_date = curr.endOf("month").format("YYYY-MM-DD");
      }
      return dateRange;
    },
    showCharts() {
      return (
        this.currTabObj &&
        this.currTabObj.showCharts &&
        this.data &&
        this.data.length > 0
      );
    },
    isShowExportToolbar() {
      return (
        this.currTabObj &&
        this.currTabObj.showExportToolbar &&
        this.data &&
        this.data.length > 0
      );
    },
  },
  watch: {
    page() {
      this.onChange();
    },
    tabData() {
      this.data = null;
      this.getData();
    },
  },
  methods: {
    sortedData(configName) {
      const vm = this;
      const oData = this.$eagleLodash.cloneDeep(this.data);
      const obj = {};
      if (oData) {
        for (let i in this[configName]) {
          const curr = this[configName][i];
          const key = curr.valueKey;
          obj[key] = this.$eagleLodash.cloneDeep(
            oData.sort((a, b) => {
              if (vm.dataSortIsDesc) {
                return a[key] < b[key] ? -1 : 1;
              } else {
                return a[key] > b[key] ? -1 : 1;
              }
            })
          );
        }
      }

      return obj;
    },
    getTopData(configName) {
      const objData = this.sortedData(configName);
      const newObj = {};
      for (let j in objData) {
        const data = objData[j];
        const obj = {
          top: [],
          others: [],
        };
        for (let i in data) {
          const curr = data[i];
          let key = "others";
          if (i < this.setTopNumber) {
            key = "top";
          }
          obj[key].push(curr);
        }
        newObj[j] = obj;
      }

      return newObj;
    },
    setPieChartsData() {
      const objData = this.getTopData("pieChartsConfig");
      this.pieChartsData = [];
      for (let j in objData) {
        const currData = objData[j];
        const { top, others } = currData;
        const obj = this.$eagleLodash.cloneDeep(this.pieChartsDataForm);
        obj.datasets[0].backgroundColor = this.chartColors;
        for (let i in top) {
          const curr = top[i];
          obj.labels.push(curr.name);
          obj.datasets[0].data.push(curr[j]);
        }
        let othersValue = 0;
        for (let i in others) {
          const curr = others[i];
          othersValue += curr[j];
        }
        if (othersValue > 0) {
          obj.labels.push("其它");
          obj.datasets[0].data.push(othersValue);
        }
        this.pieChartsData.push(obj);
      }
    },
    setLineChartLabels(diff) {
      const arr = [];
      for (let n = 1; n <= diff; n++) {
        arr.push(n);
      }
      return arr;
    },
    setLinChartsData(objData) {
      this.lineChartsData = [];
      if (objData) {
        const searchDateRange = this.searchDateRange;
        const sDate = Moment(searchDateRange.start_date);
        const eDate = Moment(searchDateRange.end_date);
        const isDaily = this.currDateOption.type == "day";
        const diffType = isDaily ? "days" : "months";
        const diff = eDate.diff(sDate, diffType) + (isDaily ? 0 : 1);

        const objDataKeys = Object.keys(objData);
        const objDataLength = objDataKeys.length;

        objDataKeys.forEach((key, index) => {
          const data = objData[key];
          const dataObj = {
            labels: Array.from({ length: diff }, (_, i) => i + 1),
            datasets: [],
          };
          const valueKey = key == "count_number" ? "count" : key;

          const { top } = data;
          Object.values(top)
            .slice(0, 5)
            .forEach((curr, i) => {
              const datasetObj = {
                label: curr.name,
                borderColor: this.chartColors[i],
                backgroundColor: "transparent",
                fill: false,
                data: Array.from({ length: diff }, (_, j) => {
                  j += 1;
                  let value = 0;

                  if (curr.list) {
                    const currObj = curr.list.find((item) => {
                      return item.dateKey == j;
                    });
                    if (currObj && currObj[valueKey]) {
                      value = currObj[valueKey];
                    }
                  }
                  return value;
                }),
              };
              dataObj.datasets.push(datasetObj);
            });

          this.lineChartsData.push(dataObj);

          if (index + 1 === objDataLength) {
            this.lineChartsDataLoaded = true;
          }
        });
      }
    },
    parseQuery() {
      const query = this.$eagleLodash.cloneDeep(this.$route.query);
      if (query.filter) {
        const filter = qs.parse(this.$route.query.filter);
        this.month = Moment(filter.created_at).format("YYYY-MM");
      }
    },
    async onChange() {
      await this.$nextTick();
      this.getData();
    },
    async getData() {
      this.$store.dispatch("loading/active");
      try {
        this.data = null;
        let res = await this.indexApi(this.apiParams);
        // this.pager = res.pager
        const objectKeys = Object.keys(res);
        if (objectKeys.includes("data")) {
          res = res.data;
        }
        this.dataFromApi = this.$eagleLodash.cloneDeep(res);
        this.getDataCallback(res);
      } catch (err) {
        console.error(err);
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
    async getReportByDate() {
      this.$store.dispatch("loading/active");
      this.lineChartsDataLoaded = false;
      try {
        const objData = this.getTopData("lineChartsConfig");
        const type = this.currDateOption.type;
        const key = this.currTabObj.key;

        let ids = [];
        for (let k in objData) {
          const currObj = objData[k];
          if (currObj.top[0].id) {
            const currIds = currObj.top.map((curr) => curr.id);
            if (currIds) {
              ids = [...currIds, ...ids];
            }
          }
        }
        if (ids.length == 0) {
          return;
        }
        ids = ids.filter((value, index, self) => self.indexOf(value) === index);
        const filter = {
          [key + "_ids"]: ids.join(","),
        };

        this.$api.collection.reportApi
          .reportByDate({
            tokenRole: this.tokenRole,
            tokenRoleId: this.tokenRoleId,
            key: key,
            type: type,
            params: {
              filter,
              ...this.params,
            },
          })
          .then((response) => {
            let groupedData = {};
            const idKey = `${this.currTabObj.key}_id`;
            response.data.forEach((item) => {
              if (type == "month") {
                item["dateKey"] = Moment(item.date).month();
              } else {
                item["dateKey"] = Moment(item.date).date();
              }
              if (!groupedData[item[idKey]]) {
                groupedData[item[idKey]] = [];
              }
              groupedData[item[idKey]].push(item);
            });

            Object.keys(objData).forEach((key) => {
              objData[key].top = objData[key].top.map((curr) => {
                return {
                  ...curr,
                  list: groupedData[curr.id] ? groupedData[curr.id] : [],
                };
              });
            });
            this.setLinChartsData(objData);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (err) {
        console.error(err);
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
    async getCoData() {
      this.$store.dispatch("loading/active");

      const apiEndpoints = {
        store: this.$api.collection.storeApi.coProviderIndex,
        provider: this.$api.collection.providerApi.clientIndex,
      };

      try {
        const params = {
          page: 1,
          per: 999,
          ...this.paramsForCoData,
        };
        const apiCall = await apiEndpoints[this.tokenRole];
        if (!apiCall) {
          throw new Error("Invalid API endpoint.");
        }
        const res = await apiCall(this.tokenRoleId, params);
        if (res && res.data) {
          this.getCoDataCallback(res.data);
        } else {
          throw new Error("Invalid response data.");
        }
      } catch (error) {
        console.error("Error fetching co data:", error);
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
    async exportData(exportType) {
      const PDF_EXPORT = "PDF";
      const EXCEL_EXPORT = "EXCEL";

      if (exportType === PDF_EXPORT) {
        const originalTitle = document.title;
        document.title = this.currFolderName;
        await new Promise((resolve) => setTimeout(resolve, 100));
        window.print();
        setTimeout(() => {
          document.title = originalTitle;
        }, 500);
      } else if (exportType === EXCEL_EXPORT) {
        const obj = {
          list: this.tableOptions,
          folderName: this.currFolderName
            ? this.currFolderName
            : this.defaultFileName,
        };
        const url =
          this.tokenRole == "store"
            ? `/provider-store/${this.layoutProviderId}`
            : "/linerp";
        try {
          this.$helper.openExternalBrowser("", `${url}/download/excel`, {
            data: encodeURIComponent(JSON.stringify(obj)),
            content: 1,
            complete: 1,
          });
        } catch (error) {
          console.error(error);
          return;
        }
      } else {
        console.error("Invalid export type.");
      }
    },
    getCoDataCallback(data) {
      const defaultOption = {
        text: "全部",
        value: null,
      };
      const newData = data.map((item) => ({
        text: item.name,
        value: item[`${this.tokenRolePositionRole}_id`] || item[`${this.tokenRolePositionRole}Id`],
      }));

      this.optionsPositionRole = [defaultOption, ...newData];
    },
  },
};
