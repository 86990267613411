<script>
/* https://www.npmjs.com/package/vue-chartjs/v/3.5.1 */
import { Pie } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  extends: Pie,
  props: {
    chartData: {
      type: Object,
      default: null
    },
  },
  data: () => ({
    timeout: null,
    options: {
      plugins: {
        datalabels: {
          formatter: (value, context) => {
            var dataset = context.chart.data.datasets[context.datasetIndex];
            var total = dataset.data.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
            var percentage = Math.round((value / total) * 100);
            return percentage > 0 ? percentage + '%' : '';
          },
          color: '#fff',
        }
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            let dataset = data.datasets[tooltipItem.datasetIndex];
            let label = data.labels[tooltipItem.index]
            let meta = dataset._meta[Object.keys(dataset._meta)[0]];
            let total = meta.total;
            let currentValue = dataset.data[tooltipItem.index];
            let percentage = parseFloat((currentValue / total * 100).toFixed(1));
            return ' ' + label + ' $' + Math.round(currentValue) + ' (' + percentage + '%)';
          },
          afterLabel: function (tooltipItem, data) {
            return ''
          }
        },
      },
    },
  }),
  beforeDestroy() {
    this.clearTimeout()
  },
  mounted() {
    this.addPlugin(ChartDataLabels);
    this.renderChart(this.chartData, this.options)
  },
  methods: {
    clearTimeout() {
      window.clearTimeout(this.timeout)
      this.timeout = null
    },
    reRenderAction() {
      this.clearTimeout()
      this.timeout = window.setTimeout(() => {
        this.renderChart(this.chartData, this.options)
      }, 500)
    },
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.reRenderAction()
      },
    }
  },
}
</script>