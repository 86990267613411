<template>
  <v-sheet rounded class="elevation-1">
    <v-container>
      <v-row :class="bgColorClass" class="white--text">
        <v-col v-for="(header, index) in headers" :key="index" :cols="header.cols" :class="header.class">
          <span>{{ header.text }}</span>
        </v-col>
      </v-row>

      <div v-if="contents.length == 0">
        <v-row class="mt-5">
          <v-col class="d-flex justify-center align-center">
            <p>無相關資料</p>
          </v-col>
        </v-row>
      </div>

      <template v-else>
        <v-row v-for="(row, index) in contents" class="" :key="index">
          <v-col v-for="(content, index) in row" :key="index" :cols="content.cols" :class="content.class">
            <span>{{ handleValue(content) }}</span>
          </v-col>
        </v-row>
      </template>

    </v-container>
  </v-sheet>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: () => (
        [
          { text: '排名', cols: 2, class: "text-center" },
          { text: '客戶', class: "text-center" },
          { text: '訂單', cols: 2, class: "text-center" },
          { text: '總金額', class: "text-center" },
        ]
      )
    },
    contents: {
      type: Array,
      default: () => (
        [
          { text: '1', cols: 2, class: "text-center" },
          { text: '亞米馥-中和店', class: "text-center" },
          { text: '10筆', cols: 2, class: "text-center" },
          { text: '1000000', class: "text-center", type: 'price' },
        ]
      )
    },
  },
  data: () => ({

  }),
  computed: {
    tokenRole() {
      return this.$store.getters['token/tokenRole']
    },
    bgColorClass() {
      return this.tokenRole === 'store' ? 'store-bg-color' : 'provider-bg-color'
    }
  },
  methods: {
    handleValue(content) {
      const { text, type } = content
      if (type == 'price') {
        return `${this.$fixedPrice(text, 0)} 元`
      }
      return text
    }
  },
}
</script>