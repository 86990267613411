<script lang="babel" type="text/babel">
import { Line } from 'vue-chartjs'
import vueChart from 'components/charts/vueChart'
export default {
  mixins: [vueChart],
  extends: Line,
}
</script>

<style lang="sass" type="text/sass" scoped></style>
