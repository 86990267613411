<template>
  <div>
    <v-container class="d-print-none">
      <page-title class="mb-5" title="月報表" />

      <div class="mb-5">
        <v-select
          dense
          outlined
          hide-details
          :items="dateOptions"
          label="選擇月份"
          v-model="month"
          @change="onChange"
        ></v-select>
      </div>

      <div class="mb-5">
        <v-select
          dense
          outlined
          hide-details
          :items="optionsPositionRole"
          label="選擇供應商"
          v-model="selected"
          @change="onChange"
        ></v-select>
      </div>
    </v-container>

    <div class="mb-5 d-print-none">
      <tabs v-model="tabData" :options="options" />
    </div>

    <v-container>
      <v-row class="pa-1 d-print-none" v-if="data && data.length > 0">
        <v-col cols="12" md="6" class="py-0">
          <v-toolbar
            class="d-print-none d-flex justify-end float-left"
            v-if="currTabObj.key == 'provider'"
            flat
            dense
            slot="list-data-title"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="primary" small class="mx-2">
                  排序方式
                </v-btn>
              </template>

              <v-list dense class="d-print-none">
                <template v-for="(item, index) in sortOptions">
                  <v-list-item :key="index" dense @click="changeSort(item.key)">
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-col>
        <v-col cols="12" md="6" class="py-0">
          <v-toolbar
            flat
            dense
            slot="list-data-title"
            class="d-flex justify-end float-right"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="primary" small class="mx-2">
                  {{ "action.export" | t }}
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item dense @click="exportData('PDF')">
                  <v-list-item-title>
                    {{ "action.picking_list.export.pdf" | t }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item dense @click="exportData('EXCEL')">
                  <v-list-item-title>
                    {{ "action.picking_list.export.excel" | t }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row class="mt-0" v-if="data">
        <v-col class="mb-10" cols="12">
          <report-form
            :headers="tableOptions.header"
            :contents="tableOptions.content"
          ></report-form>
        </v-col>
        <template v-if="data && data.length > 0">
          <template v-for="(data, index) in pieChartsData">
            <v-col
              class="px-4"
              cols="12"
              md="4"
              :key="'pie' + index"
              v-if="tabData == 0 || (tabData == 1 && !selected)"
            >
              <pie-chart :chartData="data"></pie-chart>
            </v-col>
          </template>
          <template v-for="(item, index) in lineChartsData">
            <v-col class="px-4" cols="12" md="4" :key="'line' + index">
              <template v-if="lineChartsDataLoaded">
                <line-chart
                  :chartData="item"
                  :options="lineChartOptions"
                ></line-chart>
              </template>
              <template v-else>
                <v-skeleton-loader
                  v-bind="attrs"
                  type="image, image, image"
                ></v-skeleton-loader>
              </template>
            </v-col>
          </template>
        </template>
      </v-row>
    </v-container>

    <!-- 分頁 -->
    <v-pagination
      v-if="pager"
      v-model="page"
      :length="pager.pages"
      :total-visible="6"
    ></v-pagination>
    <div v-if="pager" style="height: 60px"></div>
  </div>
</template>

<script>
import reportMixins from "@/mixins/report.js";
import qs from "qs";
import tabs from "@/modules/base/components/tabs.vue";
import reportForm from "@/components/reportForm/index.vue";
import dayjs from "dayjs";

import Moment from "moment";

import PieChart from "@/components/charts/PieChart.vue";
import LineChart from "@/components/charts/lineChart.vue";

export default {
  mixins: [reportMixins],
  components: {
    tabs,
    reportForm,
    PieChart,
    LineChart,
  },
  data: () => ({
    options: [
      // 之後如有第三個tab修改請參照 provider 同頁寫法
      {
        id: 1,
        text: "商品排行",
        key: "product",
        showCharts: true,
        showExportToolbar: true,
      },
      {
        id: 2,
        text: "供應商排行",
        key: "provider",
        showCharts: true,
        showExportToolbar: true,
      },
    ],
    sortOptions: [
      { id: 1, text: "消費金額", key: "amount" },
      { id: 2, text: "數量", key: "shipping" },
    ],
    attrs: {
      class: "hv-100",
    },
  }),
  computed: {
    tableOptions() {
      let tableHeaderKey = "TableHeaders";
      let TableContentKey = "TableContents";

      let key = this.currTabObj.key;
      return {
        header: this[key + tableHeaderKey],
        content: this[key + TableContentKey],
      };
    },
    providerTableHeaders() {
      return [
        { text: "排名", cols: 2, class: "text-center" },
        { text: "商品", class: "text-center" },
        { text: "訂單", cols: 2, class: "text-center" },
        { text: "消費金額", class: "text-center" },
      ];
    },
    providerTableContents() {
      return this.data.map((item) => {
        return [
          { text: item.rank, cols: 2, class: "text-center" },
          { text: item.name, class: "text-center" },
          { text: `${item.count}`, cols: 2, class: "text-center" },
          { text: item.amount, class: "text-center", type: "price" },
        ];
      });
    },
    productTableHeaders() {
      return [
        { text: "排名", cols: 2, class: "text-center" },
        { text: "商品", class: "text-center" },
        { text: "數量", cols: 2, class: "text-center" },
        { text: "總金額", class: "text-center" },
      ];
    },
    productTableContents() {
      return this.data.map((item) => {
        return [
          { text: item.rank, cols: 2, class: "text-center" },
          { text: item.name, class: "text-center" },
          { text: `${item.count}`, cols: 2, class: "text-center" },
          { text: item.amount, class: "text-center", type: "price" },
        ];
      });
    },
    query() {
      return {
        filter: qs.stringify(this.queryFilter, { arrayFormat: "indices" }),
      };
    },
    queryFilter() {
      return {
        created_at: this.month,
      };
    },
    tokenRoleId() {
      return this.$store.getters[`member/storeId`];
    },
    indexApi() {
      return this.currTabObj.key == "product"
        ? this.$api.collection.reportApi.storeReportGetProduct
        : this.$api.collection.reportApi.storeReportGetProvider;
    },
    params() {
      const dateRange = this.searchDateRange;

      const params = {
        pager: false,
        start_date: dateRange.start_date,
        end_date: dateRange.end_date,
      };

      return params;
    },
    paramsForCoData() {
      return {};
    },
    apiParams() {
      const params = this.$eagleLodash.cloneDeep(this.params);
      if (this.selected) {
        params.filter = {
          provider_id: this.selected,
        };
      }
      return {
        store: this.tokenRoleId,
        params: params,
      };
    },
    memberProviders() {
      return this.$store.getters["member/memberProviders"];
    },
  },
  mounted() {
    this.$root.gaLogEvent("商店_報表頁");
  },
  watch: {
    query: {
      deep: true,
      async handler(newValue, oldValue) {
        if (!this.init) return;
        if (this.$eagleLodash.isEqual(newValue, oldValue)) return;
        this.$router.push({ query: this.query });
        await this.getData();
      },
    },
    tabData(val) {
      if (val == 0) {
        this.changeSort("amount");
      }
    },
  },
  async created() {
    this.init = false;
    this.parseQuery();
    await this.getData();
    await this.getCoData();
    this.init = true;
  },
  methods: {
    changeSort(key) {
      this.dataSortKey = key;
      this.getDataCallback(this.dataFromApi);
    },
    getDataCallback(data) {
      const sortKey = this.dataSortKey;
      data.sort((a, b) => b[sortKey] - a[sortKey]);

      const tabKey = this.currTabObj.key;
      const formatItem = (item, index) => ({
        id: item[tabKey + "_id"],
        rank: index + 1,
        name: tabKey === "product" ? item.name : item.provider_name,
        count_number:
          tabKey === "product"
            ? Math.round(
                Number(
                  this.$helper.reverseShippingCount(
                    item.shipping,
                    item.shipping_unit,
                    2
                  )
                )
              )
            : item.count,
        count:
          tabKey === "product"
            ? `${this.$helper.reverseShippingCount(
                item.shipping,
                item.shipping_unit,
                2
              )} ${item.shipping_unit}`
            : `${item.count} 筆`,
        amount: Math.round(item.amount),
      });

      this.data = data.map(formatItem);

      this.setPieChartsData();
      this.getReportByDate();
    },
  },
};
</script>

<style lang="scss"></style>
